export const Service = {
	namespaced: "User",
	api: {
		// 获取首页广告
		SendMessage: () => ({
			url: "/yh/webappreact/dy!sendcode.action",
			version: "",
			method: "POST"
		}),
		// 注册
		RegisterAction: () => ({
			url: "/yh/webappreact/dy!zhuce.action",
			version: "",
			method: "POST"
		}),
		// 获取用户信息
		GetUserInfo: ({ code, openid }) => ({
			url: openid ? `/yh/webappreact/dy!getWeixinms.action?openId=${ openid }` : `/yh/webappreact/dy!getWeixinms.action?code=${code}`,
			version: "",
			method: "POST"
		}),
	}
}

