import React, { useEffect } from "react"
import routers from "./../router"
import { renderRoutes } from "react-router-config"
import { connect, Provider } from "react-redux"
import { HashRouter, BrowserRouter } from "react-router-dom"
import store from "./../store"
import { getQueryVariable } from "../utils/helper"
import { $GetUserInfo } from "@/store/Modules/UserModule"
import axiosrequest from "../utils/axios"

//https://github.com/kampiu/medicationBox

const Container = connect((state) => ({
	ImtIsAuth: state.getIn(["User", "isAuth"])
}), {
	$GetUserInfo
})(({ $GetUserInfo, ...props }) => {
	
	const {
		ImtIsAuth,
	} = props
	
	const IsAuth = ImtIsAuth
	
	useEffect(() => {
 
		// if(  axiosrequest.defaults.baseURL.indexOf("127.")!=-1 || axiosrequest.defaults.baseURL.indexOf("192.")!=-1
		//        ||   axiosrequest.defaults.baseURL.indexOf("10.20")!=-1     ){
		//
		// 	$GetUserInfo({
		// 			  openId: "ospyHv5lHzdXgily3N_Xufy2fqDc"
		// 			  //"ospyHvwqoYBG1bmimPliGGSompyg"   王工
		// 			  //ospyHv5lHzdXgily3N_Xufy2fqDc    yqh
		// 			  //ospyHv6RZV6hZ4USumt6HVchIY2M
		// 			  //ospyHv-puXg4CKoGltWssuHTO75g  KAM
		// 			  //ospyHv08IB5HT1HbWoCuqH0sxt8k  皇总
		// 			  //ospyHv0dN0N9FDTfggzTJL35yRRw  皇总2
		// 			  //ospyHv9i_13oCHBB0hyqNi6wzVr4   李工
		// 		  })
		//      return ;
		// }
		//   $GetUserInfo({
		//   	openId: "ospyHv5lHzdXgily3N_Xufy2fqDc"
		//   })
		//  return ;
		
		if (!IsAuth && !getQueryVariable("code")) {
			    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbb821096047b255f&redirect_uri=${ encodeURIComponent(window.location.href) }&response_type=code&scope=snsapi_userinfo&state=&connect_redirect=1#wechat_redirect`
		      // window.location.href="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbb821096047b255f&redirect_uri=http%3A%2F%2Fyaohe.xiangshiinfo.com&response_type=code&scope=snsapi_userinfo&state=&connect_redirect=1#wechat_redirect";
		} else if (!IsAuth && getQueryVariable("code")) {
			$GetUserInfo({
				code: getQueryVariable("code")
			})
		}
		// TODO 调试
		// $GetUserInfo({
		// 	openId: "ospyHv-puXg4CKoGltWssuHTO75g"
		// })
		// $GetUserInfo({
		// 	openId: "ospyHv08IB5HT1HbWoCuqH0sxt8k"
		// })
	}, [])
	
	// console.log("routers", routers, store.getState().getIn(["User", "userInfo"]).toJS(), props)
	return (
		<>
			{ renderRoutes(routers) }
		</>
	)
})

function App ({ ...props }) {
	
	return (
		<>
			<Provider store={ store }>
				<BrowserRouter>
					<Container { ...props } />
				</BrowserRouter>
			</Provider>
		</>
	)
}

export default App
