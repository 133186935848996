import { fromJS } from "immutable"
import Service from "@/service"

const LOGIN_ACTION = "LOGIN_ACTION"
const INIT_OPEN_ID = "INIT_OPEN_ID"

let UserStore = fromJS({
	isAuth: false,
	userInfo: {},
	openId: ""
})

export const reducer = (state = UserStore, action) => {
	switch (action.type) {
		case LOGIN_ACTION:
			const { userInfo, isAuth } = action.payload
			return state.merge({
				userInfo: fromJS(userInfo),
				isAuth: fromJS(isAuth)
			})
		case INIT_OPEN_ID:
			return state.set("openId", fromJS(action.payload))
		default:
			return state
	}
}

export const $InitialOpenId = (payload) => ({
	type: INIT_OPEN_ID,
	payload
})

export const $GetUserInfo = ({ openId = "", code}) => async dispatch => {
	let response = {}
	if(code){
		response = await Service["User/GetUserInfo"]({
			code
		})
	}else{
		response = await Service["User/GetUserInfo"]({
			openid: openId
		})
	}
	const { status, data } = response
	
	const { WxMpUser = {}, member = {} } = data
	
	dispatch({
		type: INIT_OPEN_ID,
		payload: WxMpUser.weiXinId
	})
	dispatch({
		type: LOGIN_ACTION,
		payload: {
			userInfo: {
				WxMpUser,
				member
			},
			isAuth: status === 20000
		}
	})
}

export const $HandleLogin = (data) => ({
	type: LOGIN_ACTION,
	data
})
