import React, { Component } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"

const AuthLayout = (WrapperComponent) => connect((state) => ({
	ImtUserInfo: state.getIn(["User", "userInfo"])
}), {})(({ ImtUserInfo, ...props }) => {
	
	const { member } = ImtUserInfo.toJS()
	const { pathname = "" } = props.location
	
	// if(!member && !["/", "/medication/reminder", "/user/register"].includes(pathname)){
	// 	const history = useHistory()
	// 	history.replace(`/user/register`)
	// }
	
	return (
		<WrapperComponent { ...props } />
	)
})

export default AuthLayout
