import axios from "axios"
import { Toast } from "antd-mobile"
import { useHistory } from "react-router-dom"
import store from "./../store"

export const Headers = {
	// "Content-Type": "application/x-www-form-urlencoded",
	"Accept": "application/json",
	"Content-Type": "application/json;charset=UTF-8",
	 //  "openId": "ospyHv5lHzdXgily3N_Xufy2fqDc"
	//"openId": "ospyHv5lHzdXgily3N_Xufy2fqDc"
}

const request = axios.create({
	 //  baseURL: "http://10.20.224.18/" ,  //process.env.REACT_APP_BASE_API
	//  baseURL: "http://127.0.0.1/" ,  //process.env.REACT_APP_BASE_API
	
	 baseURL: "http://yaohe.xiangshiinfo.com",
	  //  baseURL: process.env.REACT_APP_BASE_API,
	headers: Headers,
	timeout: 60000,
	timeoutErrorMessage: "网络请求超时",
	// transformRequest: [(data = {}, headers) => {
	// 	const { uploadfiles } = data
	// 	if (uploadfiles) {
	// 		const RequestForm = new FormData()
	// 		RequestForm.append("uploadfiles", uploadfiles)
	// 		return RequestForm
	// 	} else {
	// 		console.log("uploadfiles", uploadfiles, qs.parse(data))
	// 		// return qs.stringify(data)
	// 		return qs.parse(data)
	// 	}
	// }]
	// withCredentials: true
});



// 请求拦截
// axios.defaults.timeout = 60000;
request.interceptors.request.use(
	config => {
		
		//设置请求头
		config.headers = {
			...config.headers,
			...Headers,
		}
		 
		const openId = store.getState().getIn(["User", "openId"])
		if (openId && openId !== "") {
			config.headers.openId = openId
		}
		
		let authorization = localStorage.getItem("authorization")
		if (authorization) {
			config.headers.authorization = authorization
		}
		
		const { uploadfiles } = (config.data || {})
		if (uploadfiles) {
			config.headers["Content-Type"] = "application/x-www-form-urlencoded"
			const RequestForm = new FormData()
			RequestForm.append("uploadfiles", uploadfiles)
			config.data = RequestForm
		}
		
		
		console.log("--config--", config)
		return config
	},
	error => {
		return Promise.reject(error)
	}
)

//响应拦截
request.interceptors.response.use(
	({ data, headers }) => {
		const { status } = data
		if (status && status !== 20000 && status !== 304) {
			Toast.info(data.message)
		}else if(status === 302){
			Toast.info("获取信息错误")
			 window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbb821096047b255f&redirect_uri=${ encodeURIComponent(window.location.href) }&response_type=code&scope=snsapi_userinfo&state=&connect_redirect=1#wechat_redirect`
			 
		} else if (status && status === 201) {
			Toast.info("请绑定微信")
			const history = useHistory()
			history.replace(`/user/register`)
		}else{
		
		}
		// if (data.code && (data.code === 3002 || data.code === 3003)) {
		// 	window.location.href = `${ window.location.origin }/#/login`
		// 	message.error('请重新登录')
		// 	return data
		// } else {
		// 	const response = headers.authorization ? {
		// 		...data.data,
		// 		authorization: headers.authorization
		// 	} : data.data
		return data
		// }
	},
	error => {
		// 获取状态码
		// const { status } = error.response
		// if (status && status === 1015) {
		// 	message.error('请重新登录')
		// 	//清楚token
		// 	localStorage.removeItem('token')
		// 	//跳转到登录页面
		// 	// router.push('/login')
		// }
		return Promise.reject(error)
	}
)

export default request
