// import service from './../../service'
import { fromJS } from "immutable"
// import cloneDeep from 'lodash/cloneDeep'
import Service from "./../../service"

const GET_DEVICE_LIST = "GET_DEVICE_LIST"
const GET_SHAPE_LIST = "GET_SHAPE_LIST"
const INITIAL_BOX_LIST = "INITIAL_BOX_LIST"
const CHANGE_TOUCH_INDEX = "CHANGE_TOUCH_INDEX"
const CHANGE_MEDICATION_BOX_STATE = "CHANGE_MEDICATION_BOX_STATE"

let MedicationStore = fromJS({
	deviceList: [],
	box: [],        // 药仓
	shapeList: [],
	touchIndex: null,      // 药盒下表
})

export const reducer = (state = MedicationStore, action) => {
	switch (action.type) {
		case GET_DEVICE_LIST:
			return state.merge({
				touchIndex: 0,
				deviceList: fromJS(action.payload)
			})
		// return state.set("deviceList", fromJS(action.payload))
		case GET_SHAPE_LIST:
			return state.set("shapeList", fromJS(action.payload))
		case INITIAL_BOX_LIST:
			return state.set("box", fromJS(action.payload))
		case CHANGE_TOUCH_INDEX:
			return state.set("touchIndex", fromJS(action.payload))
		case CHANGE_MEDICATION_BOX_STATE:
			const ImtBox = state.get("box")
			const Box = ImtBox.toJS()
			Box.some(item => {
				if (item.id === action.payload) {
					item.isstopLong = !item.isstopLong
					return true
				}
			})
			return state.set("box", fromJS(Box))
		default:
			return state
	}
}

export const $GetShapeList = (payload) => ({ type: GET_SHAPE_LIST, payload })
export const $GetMedicationBox = (payload) => ({ type: INITIAL_BOX_LIST, payload })
export const $TouchIndex = (payload) => ({ type: CHANGE_TOUCH_INDEX, payload })
export const $ChangeMedicationBoxState = (id) => ({ type: CHANGE_MEDICATION_BOX_STATE, payload: id })

export const $GetDeviceList = () => async dispatch => {
	const { status, data } = await Service["Medication/GetMedicationInfo"]()
	
	dispatch({
		type: GET_DEVICE_LIST,
		payload: data.devicelist && Array.isArray(data.devicelist) ? data.devicelist : []
	})
}
