
import { Service as User } from './UserModule'
import { Service as Medication } from './MedicationModule'
import { Service as Health } from './HealthModule'
import { Service as Setting } from './SettingModule'
import { Service as Common } from './CommonModule'

export default [
	User,
	Medication,
	Health,
	Common,
	Setting,
]
