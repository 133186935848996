export const Service = {
	namespaced: "Health",
	api: {
		// 获取所有疾病类型
		GetDiseaseList: ({disease="",id="" ,pageno=""}) => ({
			url: `/yh/webappreact/dy!getDiseaseList.action?pageno=${pageno}&equal__status=1&like__disease=${disease}`,
			version: "",
			method: "GET"
		}),

//包含无的项
		GetDiseaseList2: ({disease="",id=""}) => ({
			url: `/yh/webappreact/dy!getDiseaseList.action?method=addwu&equal__status=1&like__disease=${disease}`,
			version: "",
			method: "GET"
		}),

		 //如果有传deviceid的是设备绑定语音
		 saveDevicePlay: ({disease="",deviceid=""}) => ({
			url: `/yh/webappreact/dy!saveDevicePlay.action?disease_type_id=${disease}&deviceid=${deviceid}`,
			version: "",
			method: "GET"
		}),

		// 获取疾病类型语音
		GetKnowledgeSpeechList: ({ id="",deviceid="",like__title="" }) => ({
			url: `/yh/webappreact/dy!getKnowledgeSpeechList.action?equal__disease_type_id=${ id }&deviceid=${deviceid}&like__title=${like__title}`,
			version: "",
			method: "GET"
		}),

		GetHistory: ({ type="",deviceid="" }) => ({
			url: `/yh/webappreact/dy!knowledgeSpeechPlayHistort.action?type=${ type }&deviceid=${deviceid}`,
			version: "",
			method: "GET"
		}),

		//保存播放某个语音
		savePlayHistory: ({ type,id }) => ({
			url: `/yh/webappreact/dy!knowledgeSpeechPlay.action?type=${ type }&id=${id}`,
			version: "",
			method: "GET"
		}),
	}
}

