export const Service = {
	namespaced: "Common",
	api: {
		// 图片上传
		UploadImage: () => ({
			url: "/yh/webappreact/dy!uploadFile.action",
			version: "",
			method: "POST"
		}),
		GetCommonParams: ({ key }) => ({
			url: `/yh/webappreact/dy!getParameterMs.action?name=${ key }`,
			version: "",
			method: "GET"
		}),
		GetColorList: () => ({
			url: `/yh/webappreact/dy!getParameterMs.action?name=yanse`,
			version: "",
			method: "GET"
		}),
		GetShapeList: () => ({
			url: `/yh/webappreact/dy!getParameterMs.action?name=xingzhuang`,
			version: "",
			method: "GET"
		}),
		GetJiLiangList: () => ({
			url: `/yh/webappreact/dy!getParameterMs.action?name=jiliang`,
			version: "",
			method: "GET"
		}),
		GetfuyongtianshuList: () => ({
			url: `/yh/webappreact/dy!getParameterMs.action?name=fuyongtianshu`,
			version: "",
			method: "GET"
		}),
		GetTimeList: () => ({
			url: `/yh/webappreact/dy!getParameterMs.action?name=shijiandian`,
			version: "",
			method: "GET"
		}),
		GetTimeListById: ({ deviceId = "",drugBoxId="" }) => ({
			url: `/yh/webappreact/dy!getParameterMs.action?deviceId=${ deviceId }&name=devicceshijiandian&drugBoxId=${drugBoxId}`,
			version: "",
			method: "GET"
		}),
		GetJSSDKConfig: () => {
			console.log("发起JSSDK的当前路径 => ", window.location.href)
			return {
				url: `/yh/weixinAction/dy!getWeixinJssdk.action?jsurl=${ window.location.href }`,
				version: "",
				method: "GET"
			}
		}
	}
}

